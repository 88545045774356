import { Controller } from "stimulus"
import Axios from 'axios'
export default class extends Controller {
  static targets = ["permissions", "paths"];

  openShareToMemberModal(e) {
    const elementID = e.target.id;
    const prefix = "form-label-id-";
    const assetID = elementID.slice(elementID.indexOf(prefix) + prefix.length);
    const form = $(`#share-to-member-form-${assetID}`)[0];
    $(".popup-action").hide();
    Rails.fire(form, 'submit');
  }

  handleShareResults() {
    const [data, status, xhr] = event.detail
    this.permissionsTarget.innerHTML = xhr.response
    $("#share-to-member-modal").show();
  }

  openMoveAssetModal(e) {
    const elementID = e.target.id;
    const prefix = "move-asset-label-id-";
    const assetID = elementID.slice(elementID.indexOf(prefix) + prefix.length);
    const form = $(`#move-asset-form-${assetID}`)[0];
    $(".popup-action").hide();
    $("#move-asset-modal-ok").val(assetID);
    $("#move-asset-new-parent-id").val('first-value');
    Rails.fire(form, 'submit');
  }

  traverseMoveFolder(e) {
    const elementID = e.currentTarget.id;
    const prefix = "folder-label-id-";
    const parentID = elementID.slice(elementID.indexOf(prefix) + prefix.length);
    let form;
    if ($(e.target).hasClass("child")) {
      form = $(`#traverse-move-folder`)[0];
      $("#new-parent-hidden-field").val(parentID);
    } else {
      form = $(`#traverse-move-folder-${parentID}`)[0];
    }
    $("#move-asset-new-parent-id").val(parentID);
    Rails.fire(form, 'submit');
  }

  handleMoveAssetResults() {
    const [data, status, xhr] = event.detail
    this.pathsTarget.innerHTML = xhr.response;
    $("#move-asset-modal").show();
  }
}

export const FILE_CHUNK_SIZE = 131_072_000 // 125 MB  default unit is B
const metaCsrf = document.querySelector("meta[name='csrf-token']");
const csrfToken = metaCsrf.getAttribute('content');

export const uploadLargeFile = async ({ file, displayUploadProgress }) => {
  let upload_url = '/assets/upload_file'
  if (window.location.pathname.includes('/guests')) {
    const url = new URL(window.location.href)
    const transferLinkUUID = url.searchParams.get('transfer_link_uuid')
    upload_url = `/guests/upload_file?transfer_link_uuid=${transferLinkUUID}`
  }

  const headers = {
    'x-csrf-token': csrfToken,
    'content-type': 'application/json',
    'accept': 'application/json'
  }
  const axios = Axios.create()
  const total_parts = Math.ceil(file.size / FILE_CHUNK_SIZE)
  const group_id = $('input#group_id').val()
  const resp = await axios.post(upload_url, {
    asset: {
      filename: file.name,
      file_size: file.size,
      total_parts,
      group_id
    }
  }, { headers })
  const all_parts = await uploadFileParts({ file, urls: resp.data['urls'], displayUploadProgress })
  return axios.post(upload_url, {
    asset: {
      upload_id: resp.data['upload_id'],
      asset_id: resp.data['asset_id'],
      group_id,
      all_parts
    }
  }, { headers })
}

const uploadFileParts = async ({file, urls, displayUploadProgress}) => {
  const axios = Axios.create()
  delete axios.defaults.headers.put['Content-Type']
  const promises = []
  const allProgress = Array(urls.length).fill(0)

  for (let index = 0; index < urls.length; index++) {
    const start = index * FILE_CHUNK_SIZE
    const end = (index + 1) * FILE_CHUNK_SIZE
    const blob = index < urls.length
      ? file.slice(start, end)
      : file.slice(start)
    promises.push(axios.put(urls[index], blob, {
      onUploadProgress: (p) => {
        allProgress[index] = p.loaded
        displayUploadProgress(file.previewElement, allProgress.reduce((a, b) => a + b, 0) * 100 / file.size) 
      }
    }))
  }

  try {
    const resParts = await Promise.all(promises)
    return resParts.map((part, index) => ({
        etag: part.headers['etag'],
        part_number: index + 1
      }
    ))
  } catch (err) {
    return []
  }
}
