import $ from 'jquery';
import flatpickr from 'flatpickr';
import { manageDisplayNextPrevBtn } from '../transfer_links';
import { uploadLargeFile, FILE_CHUNK_SIZE } from '../../controllers/asset_controller'
import Dropzone from 'dropzone';

Dropzone.autoDiscover = false;
const metaCsrf = document.querySelector("meta[name='csrf-token']");
const csrfToken = metaCsrf.getAttribute('content');
const MAX_FILE_SIZE = 30_720 // 30 GB  default unit is MB

$(document).on('turbolinks:load', function () {
  // open new asset modal when click button
  $("#new-folder").on("click", function () {
    $("#new-folder-modal").show();
  });

  // open new asset modal when click button
  $("#new-file").on("click", function () {
    $(".dropzone > .dz-message > .dz-button").text("");
    $(".dropzone > .dz-message > .dz-button").append(`
      <div class="flex flex-col justify-center items-center">
        <img src="/assets/images/upload_file_to_cloud.svg" alt="cloud" class="w-16" />
        <div>
          <span class="text-orange-medium">Drag</span> or <span class="text-orange-medium">Browse</span> file to upload
        </div>
      </div>
    `);
    $("#new-file-modal").show();
  });

  function extensionToIconName(ext) {
    switch (ext) {
      case 'jpg', 'jpeg':
        return 'jpg';
      case 'png':
        return 'png';
      case 'csv':
        return 'csv';
      case 'pdf':
        return 'pdf';
      case 'doc', 'docx':
        return 'microsoft_word';
      case 'xls', 'xlsx':
        return 'microsoft_excel';
      case 'ppt', 'pptx':
        return 'microsoft_powerpoint';
      default:
        return 'file-text';
    }
  }

  if ($("body").find("#file-dropzone").length != 0) {
    const showErrorMsg = (elm, errMsg) => {
      $(elm).find(".dz-details > .dz-filename > .dz-progress-percent").removeClass("text-grey-dark");
      $(elm).find(".dz-details > .dz-filename > .dz-progress-percent").addClass("text-red-medium font-semibold");
      $(elm).find(".dz-details > .dz-filename > .dz-progress-percent").text(errMsg);
    }

    const fileDropzone = new Dropzone("#file-dropzone", {
      maxFilesize: MAX_FILE_SIZE,
      previewsContainer: ".upload-files",
    });

    fileDropzone.on("addedfile", function (file) {
      const ext = file.name.slice(file.name.lastIndexOf('.') + 1);
      const iconName = extensionToIconName(ext)
      $(file.previewElement).addClass("flex flex-row");
      $(file.previewElement).find(".dz-image > img").attr("src", `/assets/icons/${iconName}.svg`);
      $(file.previewElement).find(".dz-details").addClass("flex flex-col w-full");
      $(file.previewElement).find(".dz-details > .dz-filename").addClass("relative w-full flex flex-row mb-2 text-grey-dark");
      $(file.previewElement).find(".dz-details > .dz-filename").append(`
        <div class="dz-progress-percent absolute right-0">0%</div>
      `);
      $(file.previewElement).find(".dz-details > .dz-filename > span").addClass("w-72 truncate");
      $(file.previewElement).find(".dz-details").append($(file.previewElement).find(".dz-progress"));
      $(file.previewElement).find(".dz-size").remove();
      $(file.previewElement).find(".dz-progress").append(`
        <div class="dz-progress-bg border border-grey-lightest rounded h-2 w-full">
          <div class="dz-progress-bar bg-orange-medium rounded h-full"></div>
        </div>
      `);
      $(file.previewElement).find(".dz-error-message").hide();
      $(file.previewElement).find(".dz-error-mark").hide();
      $(file.previewElement).find(".dz-success-mark").hide();
      $(".upload-files-zone").show();
    })

    const displayUploadProgress = (elm, progress) => {
      const progress_text = progress.toFixed(2) === '100.00' ? '99.99' : progress.toFixed(2)
      $(elm)
        .find(".dz-details > .dz-progress > .dz-progress-bg > .dz-progress-bar")
        .css("width", `${progress_text}%`);
      $(elm).find(".dz-details > .dz-filename > .dz-progress-percent").text(`${progress_text}%`);
    }

    const displayUploadSuccess = (elm) => {
      $(elm).find(".dz-details > .dz-progress > .dz-progress-bg > .dz-progress-bar").css("width", '100%');
      $(elm).find(".dz-details > .dz-filename > .dz-progress-percent").removeClass("text-grey-dark");
      $(elm).find(".dz-details > .dz-filename > .dz-progress-percent").addClass("text-green-dark font-semibold");
      $(elm).find(".dz-details > .dz-filename > .dz-progress-percent").text("Success");
    }

    fileDropzone.on("sending", async (file, xhr, formData) => {
      if (file.size >= FILE_CHUNK_SIZE) {
        xhr.send = async () => {
          await uploadLargeFile({ file, displayUploadProgress })
          displayUploadSuccess(file.previewElement)
        }
      } else {
        formData.append('asset[file]', file)
        formData.append('asset[group_id]', $('input#group_id').val())
      }
    });

    fileDropzone.on("uploadprogress", function (file, progress, bytesSent) {
      displayUploadProgress(file.previewElement, progress)
    })

    fileDropzone.on("success", function (file, response) {
      displayUploadSuccess(file.previewElement)
    });

    fileDropzone.on("error", function (file, response) {
      showErrorMsg(file.previewElement, response.response)
    });
  }

  // stop close popup when click inside popup content
  $("#content-popup-recipient").on("click", function (e) {
    e.stopPropagation();
    return;
  });

  // remove recipient mail
  $("#recipient-zone").on("click", ".remove-mail", function (e) {
    const mail_box = $(e.target).parent().parent();
    const mail = mail_box.text().trim();
    const form_mail = $("#form-recipient").val();
    const index_mail = form_mail.indexOf(mail);
    let mails;
    if (index_mail == 0) {
      mails = form_mail.slice(mail.length + 1,);
    } else {
      mails = form_mail.slice(0, index_mail - 1) + form_mail.slice(index_mail + mail.length,);
    }
    $("#form-recipient").val(mails);
    $(e.target).parent().parent().remove();
    e.stopPropagation();
    return;
  });

  function add_recipient_mail() {
    const mail = $("#recipient-mail").val();

    const validRegex = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
    if (!validRegex.test(mail)) {
      $("#form-error-recipient").show();
      return;
    }
    $("#form-error-recipient").hide();

    const mail_box =
      `
    <div
      class="flex items-center bg-grey-lightest border-grey-lightest rounded w-max h-8 px-1 mr-1"
    >
      ${mail}
      <button type="button" class="remove-mail ml-2 w-4">
        <img src="/assets/icons/close.svg" alt="close icon" />
      </button>
    </div>
    `
    $("#recipient-zone").append(mail_box);

    if ($("#form-recipient").val() == '') {
      $("#form-recipient").val(mail);
    } else {
      $("#form-recipient").val($("#form-recipient").val() + ',' + mail);
    }
    $("#recipient-mail").val('');
    return;
  }

  // support enter button
  $("#recipient-mail").on("keydown", function (e) {
    if (e.key == "Enter") {
      add_recipient_mail();
    }
    return;
  })

  // add recipient mail when click button add
  $("#add-recipient-mail").on("click", function (e) {
    add_recipient_mail();
    return;
  })

  // open popup
  $("#recipient-zone").on("click", function (e) {
    $("#popup-recipient").show();
    return;
  });

  // stop close modal when click inside modal content
  $(".content-new-asset-modal").on("click", function (e) {
    e.stopPropagation();
    if (e.target.id != "recipient-zone") {
      $("#recipient-mail").val('');
      $("#popup-recipient").hide();
    }
    $(".options").hide();
    return;
  });

  // close modal
  $(".bg-new-asset-modal").on("click", function (e) {
    $("#recipient-mail").val('');
    $("#popup-recipient").hide();

    if ($(".upload-files").children().length != 0) {
      $(".confirm-delete-uploaded-asset-title").html('Confirm Delete Asset?');
      $(".confirm-delete-uploaded-asset-confirm").text('YES, DELETE NOW.');
      $(".confirm-delete-uploaded-asset-modal").show();
    } else {
      $(".new-asset-modal").hide();
    }
  });

  $(".confirm-delete-uploaded-asset-discard, .bg-confirm-delete-uploaded-asset-modal").on("click", function (e) {
    $(".confirm-delete-uploaded-asset-modal").hide();
  });

  $(".confirm-delete-uploaded-asset-confirm").on("click", function (e) {
    const searchParams = new URLSearchParams(window.location.search)
    const transferLinkUUID = searchParams.get('transfer_link_uuid')
    $.ajax({
      url: transferLinkUUID ? '/guests/delete_upload_file' : '/assets/delete_upload_file',
      type: 'DELETE',
      headers: {
        'x-csrf-token': csrfToken,
      },
      data: {
        transfer_link_uuid: transferLinkUUID
      },
    });
  })

  // flatpickr
  flatpickr('.datetimes', {
    enableTime: true,
    dateFormat: "d/m/Y h:i K",
    defaultDate: new Date().fp_incr(7),
    disable: [
      function (date) {
        // return true to disable, should enable today
        const yesterday = new Date();
        yesterday.setDate(yesterday.getDate() - 1);
        return date < yesterday;

      }
    ],
  });

  if ($(".change-expiry-date")[0]) {
    $('#remove-expire-date').on("click", function () {
      const id = $(".change-expiry-date")[0].parentElement.id;
      const data = {
        asset: {
          expiry_date: null
        }
      };
      $.ajax({
        url: `/assets/${id}`,
        type: 'PATCH',
        headers: {
          'x-csrf-token': csrfToken,
        },
        data
      });
    });
  }

  // stop close popup when click inside popup content
  $(".stimulus").on("click", ".content-popup-action", function (e) {
    e.stopPropagation();
    return;
  });

  // close popup
  $(".stimulus").on("click", ".bg-popup-action", function (e) {
    $("#popup-" + e.target.id.slice(e.target.id.indexOf("-") + 1)).hide();
  });

  // open popup and stop link when click on action zone
  $(".stimulus").on("click", ".action-zone", function (e) {
    e.stopPropagation();
    const id = e.target.id ? e.target.id : e.target.parentElement.id;
    $("#popup-" + id).show();
    return;
  });

  // change select to div and ul
  $('select.select-style').each(function () {

    // Cache the number of options
    const $this = $(this);
    const numberOfOptions = $(this).children('option').length;

    // Hides the select element
    $this.addClass('hidden');

    // Wrap the select element in a div
    $this.wrap('<div class="select"></div>');

    // Insert a styled div to sit over the top of the hidden select element
    $this.after(`
      <div
        class="styled-select absolute right-0 w-56 border border-grey-lightest
        rounded h-full w-56 px-2 pt-2 cursor-pointer">
      </div>
      <div class="trigger-styled-select absolute right-2 w-6 h-full flex self-center cursor-pointer">
        <img src="/assets/icons/black-chevron-down.svg" alt="black chevron down icon" />
      </div>
    `);

    // Cache the styled div
    const $styledSelect = $this.next('div.styled-select');

    const $triggerStyledSelect = $styledSelect.next('div.trigger-styled-select');

    // Show the first select option in the styled div
    $styledSelect.text($this.children('option').eq(0).text());

    // Insert an unordered list after the styled div and also cache the list
    const $list = $('<ul />', {
      'class': 'options border bg-white absolute right-0 z-20 hidden w-56 mt-10'
    }).insertAfter($styledSelect);

    // Insert a list item into the unordered list for each select option
    for (var i = 0; i < numberOfOptions; i++) {
      $('<li />', {
        text: $this.children('option').eq(i).text(),
        rel: $this.children('option').eq(i).val(),
        class: 'cursor-pointer border px-4 py-2 hover:bg-grey-lightest'
      }).appendTo($list);
    }

    // Cache the list items
    const $listItems = $list.children('li');

    $triggerStyledSelect.on("click", function (e) {
      e.stopPropagation();
      $('div.styledSelect.active').each(function () {
        $styledSelect.removeClass('active').next('ul.options').hide();
      });
      $styledSelect.toggleClass('active').next('ul.options').toggle();
    });

    // Show the unordered list when the styled div is clicked (also hides it if the div is clicked again)
    $styledSelect.on("click", function (e) {
      e.stopPropagation();
      $('div.styledSelect.active').each(function () {
        $(this).removeClass('active').next('ul.options').hide();
      });
      $(this).toggleClass('active').next('ul.options').toggle();
    });

    // Hides the unordered list when a list item is clicked and updates the styled div to show the selected list item
    // Updates the select element to have the value of the equivalent option
    $listItems.on("click", function (e) {
      e.stopPropagation();
      $styledSelect.text($(this).text()).removeClass('active');
      $this.val($(this).attr('rel'));
      $list.hide();
      if ($this.val() == "Custom") {
        $styledSelect.addClass('right-60 w-32');
        $styledSelect.removeClass('w-56');
        $triggerStyledSelect.addClass('right-60');
        $list.addClass('right-60 w-32');
        $list.removeClass('w-56');
        $styledSelect.parent().next('input.datetimes').show();
      } else {
        $styledSelect.parent().next('input.datetimes').hide();
        $styledSelect.addClass('w-56');
        $styledSelect.removeClass('w-32 right-60');
        $triggerStyledSelect.removeClass('right-60');
        $list.removeClass('right-60 w-32');
        $list.addClass('w-56');
      }
    });
  });

  // disable submit by enter on form upload asset
  $("#form-upload-asset").on("keydown", function (e) {
    if (e.key == "Enter") {
      return false;
    }
  });

  $(".submit-upload-asset").on("click", function (e) {
    const dataList = $("form#form-upload-asset").serializeArray();
    const dataJson = {};

    dataList.map((data) => {
      const key = data['name'].slice(data['name'].indexOf('[') + 1, data['name'].indexOf(']'));

      if (key != 'authenticity_toke') {
        dataJson[key] = data['value'];
      }
    });
    const searchParams = new URLSearchParams(window.location.search)
    const share = searchParams.get('transfer_link_uuid') ? true : false
    const checkPath = share ? `/guests/check_upload_file` : `/assets/check_upload_file`
    const postPath = share ? `/guests` : `/assets`
    $.ajax({
      url: checkPath,
      type: 'GET',
      headers: {
        'x-csrf-token': csrfToken,
        'content-type': 'application/json',
        'accept': 'application/json'
      },
      data: {
        transfer_link_uuid: searchParams.get('transfer_link_uuid')
      },
      success: () => {
        $.ajax({
          url: postPath,
          type: 'POST',
          headers: {
            'x-csrf-token': csrfToken,
            'content-type': 'application/json',
            'accept': 'application/json'
          },
          data: JSON.stringify({
            asset: dataJson,
            transfer_link_uuid: searchParams.get('transfer_link_uuid')
          }),
          dataType: "JSON",
          success: (response) => {
            const { group_id, parent_id, transfer_links } = response;
            $(".new-asset-modal").hide();
            $(".content-transfer-links-modal").val(0);
            $(".bg-transfer-links-modal").val(group_id);
            $(".transfer-link-asset").val(true);
            $(".create-transfer-link-submit-button").val(parent_id);

            if (group_id) {
              $(".transfer-link-asset").val(false);
            }

            if (transfer_links.length == 0) {
              $(".transfer-link-detail").hide();
              $(".transfer-link-url-box").hide();
              $(".transfer-link-password").hide();
              $(".transfer-link-send-other").hide();
              $(".transfer-link-prev").addClass("invisible");
              $(".transfer-link-next").addClass("invisible");
              $(".transfer-links-modal").show();
            } else {
              manageDisplayNextPrevBtn(transfer_links);
            }
          },
          error: () => {
            $(".error-upload-asset").text('Something went wrong!');
            $(".error-upload-asset").show();
          }
        });
        $(".error-upload-asset").hide();
      },
      error: () => {
        $(".error-upload-asset").show();
      }
    });
  });

  // click on asset
  $(".stimulus").on("click", ".folder-table-row", function (e) {
    const url = $(this).attr('data-link-path');
    if (url) {
      window.location = url;
    }
  });

  // stop close modal when click inside modal content
  $(".stimulus").on("click", ".content-move-asset-modal", function (e) {
    e.stopPropagation();
    return;
  });

  // close move asset modal
  $(".stimulus").on("click", ".bg-move-asset-modal", function (e) {
    $('#move-asset-modal').hide();
  });

  // open delete asset modal
  $(".stimulus").on("click", ".delete-asset", function (e) {
    const prefix = "delete-asset-";
    const assetID = this.id.slice(this.id.indexOf(prefix) + prefix.length);
    $("#confirm-asset-title").text("Confirm Delete File");
    $(".confirm-asset-confirm").text("YES, DELETE NOW.");
    $("#complete-title").html('Success Delete File!');
    $("#confirm-asset").show();
    $("#confirm-asset").val(`asset_${assetID}`);
    $('.popup-action').hide();
  });

  // stop close modal when click inside modal content
  $(".stimulus").on("click", ".content-confirm-asset-modal", function (e) {
    e.stopPropagation();
    return;
  });

  // close modal
  $(".stimulus").on("click", ".bg-confirm-asset-modal", function (e) {
    $(".confirm-asset").hide();
  });

  // close modal
  $(".stimulus").on("click", ".confirm-asset-discard", function (e) {
    $(".confirm-asset").hide();
  });

  function removeAsset(assetID) {
    $.ajax({
      url: `/assets/${assetID}`,
      type: 'DELETE',
      headers: {
        'x-csrf-token': csrfToken,
        'content-type': 'application/json',
        'accept': 'application/json'
      },
      success: (response) => {
        const { parent_id, group_id } = response;
        $(".confirm-asset").hide();
        $(".complete-asset").show();
        let path = "";
        if (parent_id) {
          path = `/assets/${parent_id}`;
        } else if (group_id) {
          path = `/groups/${group_id}`;
        } else {
          path = `/assets`;
        }
        $(".complete-discard").val(path);
      }
    });
  }

  function removeAssetPermission(permissionID) {
    $.ajax({
      url: `/asset_permissions/${permissionID}`,
      type: 'DELETE',
      headers: {
        'x-csrf-token': csrfToken,
      }
    });
  }

  // delete asset
  $(".stimulus").on("click", ".confirm-asset-confirm", function (e) {
    const element_val = $("#confirm-asset").val()
    const method = element_val.split('_')[0];
    const prefix = `${method}_`;
    const id = element_val.slice(element_val.indexOf(prefix) + prefix.length);
    if (method == 'asset') removeAsset(id);
    else if (method == 'permission') removeAssetPermission(id);
  });

  // click complete modal content
  $(".stimulus").on("click", ".content-complete-modal", function (e) {
    e.stopPropagation();
    return;
  });

  function redirectAfterCompleteModal() {
    $("#complete-modal").hide();
    if ($(".complete-discard").val()) {
      window.location.href = $(".complete-discard").val();
    } else {
      window.location.reload();
    }
  }

  // click complete modal discard button
  $(".stimulus").on("click", ".complete-discard", function (e) {
    redirectAfterCompleteModal();
  });

  // click complete modal background
  $(".stimulus").on("click", ".bg-complete-modal", function (e) {
    redirectAfterCompleteModal();
  });

  $(".star-icon").on("click", function (e) {
    const prefix = "star-icon-";
    const assetID = this.id.slice(this.id.indexOf(prefix) + prefix.length);

    if ($(this).hasClass("starred")) {
      $.ajax({
        url: `/stars/${assetID}`,
        type: 'DELETE',
        headers: {
          'x-csrf-token': csrfToken,
        }
      });
    } else {
      $.ajax({
        url: '/stars',
        type: 'POST',
        headers: {
          'x-csrf-token': csrfToken,
        },
        data: { id: assetID }
      });
    }
    return false;
  });

  $(".recent-file").on("click", function () {
    window.location = this.href;
  });

  $(".stimulus").on("click", "#move-asset-modal-ok", function () {
    const assetID = this.value;
    let parentID = $("#move-asset-new-parent-id").val();
    if (parentID == 'first-value') {
      $("#move-asset-modal").hide();
      return;
    } // open modal and click ok immediately
    else if (parentID == '') {
      parentID = null;
    }
    $.ajax({
      url: `/assets/${assetID}`,
      type: 'PATCH',
      headers: {
        'x-csrf-token': csrfToken,
      },
      data: {
        asset: { parent_id: parentID }
      }
    });
  });

  window.onbeforeunload = function () {
    const searchParams = new URLSearchParams(window.location.search)
    const transferLinkUUID = searchParams.get('transfer_link_uuid')
    $.ajax({
      url: transferLinkUUID ? '/guests/delete_upload_file' : '/assets/delete_upload_file',
      type: 'delete',
      headers: {
        'x-csrf-token': csrfToken
      },
      data: {
        transfer_link_uuid: transferLinkUUID
      },
    });
  }
});
