import $ from 'jquery';

const metaCsrf = document.querySelector("meta[name='csrf-token']");
const csrfToken = metaCsrf.getAttribute('content');
let transferLinks = [];

export function manageDisplayNextPrevBtn(links = [], direction = "") {
  transferLinks = links.length == 0 ? transferLinks : links;
  let transferLinkIdx = parseInt($(".content-transfer-links-modal").val());
  if (direction == "prev") {
    transferLinkIdx = transferLinkIdx == 0 ? 0 : transferLinkIdx - 1;
  } else if (direction == "next") {
    transferLinkIdx = transferLinkIdx == transferLinks.length - 1 ? transferLinkIdx : transferLinkIdx + 1;
  }

  $(".transfer-link-asset").text(transferLinks[transferLinkIdx]["asset_name"]);
  const emailText = `Client Email: ${transferLinks[transferLinkIdx]["email"]}`
  $(".transfer-link-email").text(emailText);
  $(".transfer-link-url").text(transferLinks[transferLinkIdx]["link_url"]);
  const passwordText = `Password: ${transferLinks[transferLinkIdx]["password"]}`
  $(".transfer-link-password").text(passwordText);
  $(".create-transfer-link-submit-button").val(transferLinks[transferLinkIdx]["asset_id"]);
  $(".content-transfer-links-modal").val(transferLinkIdx);

  if (transferLinks.length == 1) {
    $(".transfer-link-prev").addClass("invisible");
    $(".transfer-link-next").addClass("invisible");
  }
  else if (transferLinkIdx >= transferLinks.length - 1) {
    $(".transfer-link-next").addClass("invisible");
    $(".transfer-link-prev").removeClass("invisible");
  } else if (transferLinkIdx <= 0) {
    $(".transfer-link-prev").addClass("invisible");
    $(".transfer-link-next").removeClass("invisible");
  } else {
    $(".transfer-link-prev").removeClass("invisible");
    $(".transfer-link-next").removeClass("invisible");
  }

  $(".transfer-link-prev").removeClass("w-40");
  $(".transfer-link-next").removeClass("w-40");
  $(".transfer-links-modal").show();
}

function createTransferLink(assetID) {
  const clientEmail = $(".create-transfer-link-email").val();
  const validRegex = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
  if (!validRegex.test(clientEmail)) {
    return false;
  }

  const dataList = $("form#form-create-transfer-link").serializeArray();
  const dataJson = {};

  dataList.map((data) => {
    const key = data['name'].slice(data['name'].indexOf('[') + 1, data['name'].indexOf(']'));

    if (key != 'authenticity_toke') {
      dataJson[key] = data['value'];
    }
  });

  $.ajax({
    url: `/assets/${assetID}/transfer_links`,
    type: 'POST',
    headers: {
      'x-csrf-token': csrfToken,
      'content-type': 'application/json',
      'accept': 'application/json'
    },
    data: JSON.stringify({ transfer_link: dataJson }),
    success: (response) => {
      const { transfer_links } = response;
      $(".create-transfer-link-error-msg").text("");
      $(".create-transfer-link-error-msg").hide();
      $(".bg-transfer-links-modal").val(null);
      $(".transfer-link-asset").val(true);
      $(".create-transfer-link-email").val('');
      $(".create-transfer-link-modal").hide();
      $(".content-transfer-links-modal").val(0);
      manageDisplayNextPrevBtn(transfer_links);
    },
    error: (response) => {
      if (response.status) {
        const error = "This email has already registered to the system as a user,<br />please use the external email"
        $(".create-transfer-link-error-msg").html(error);
        $(".create-transfer-link-error-msg").show();
      }
    }
  });
  return true;
}

$(document).on('turbolinks:load', function () {
  // open modal
  $(".stimulus").on("click", ".create-transfer-link", function (e) {
    const prefix = "create-transfer-link-";
    const assetID = this.id.slice(this.id.indexOf(prefix) + prefix.length,);
    $(".create-transfer-link-submit-button").val(assetID);
    $(".create-transfer-link-modal").show();
  });

  $(".stimulus").on("click", ".create-transfer-link-submit-button", function (e) {
    e.stopPropagation();
    createTransferLink($(".create-transfer-link-submit-button").val());
    return;
  });

  $(".stimulus").on("click", ".transfer-link-send-other", function (e) {
    $(".create-transfer-link-modal").show();
  });

  $(".transfer-link-prev").on("click", function (e) {
    manageDisplayNextPrevBtn([], "prev");
  });

  $(".transfer-link-next").on("click", function (e) {
    manageDisplayNextPrevBtn([], "next");
  });

  $(".content-transfer-links-modal").on("click", function (e) {
    e.stopPropagation();
    return;
  })

  $(".bg-transfer-links-modal").on("click", function (e) {
    $(".transfer-links-modal").hide();
    const searchParams = new URLSearchParams(window.location.search)
    const groupID = $(".bg-transfer-links-modal").val();
    const showAsset = $(".transfer-link-asset").val();
    const assetID = $(".create-transfer-link-submit-button").val();
    if(searchParams.get('transfer_link_uuid')) {
      window.location.href = `/guests/${assetID}?transfer_link_uuid=${searchParams.get('transfer_link_uuid')}`;
    } else if (showAsset) {
      window.location.href = `/assets/${assetID}`;
    } else if (groupID) {
      window.location.href = `/groups/${groupID}`;
    }  else {
      window.location.href = `/assets`;
    }
  })

  // stop close modal when click inside modal content
  $(".stimulus").on("click", ".content-create-transfer-link-modal", function (e) {
    e.stopPropagation();
    return;
  });

  // close modal
  $(".stimulus").on("click", ".bg-create-transfer-link-modal", function (e) {
    $(".create-transfer-link-email").val('');
    $(".create-transfer-link-modal").hide();
  });

  $(".stimulus").on("click", ".transfer-link-copy-link", function (e) {
    const id = this.id.slice(this.id.lastIndexOf('-') + 1);
    $("#transfer-link-link-" + id).get(0).select();
    document.execCommand("copy");
    $("#copied-link-" + id).show();
    setTimeout(() => {
      $("#copied-link-" + id).fadeOut(1000);
    }, 1000);
  });

  // open password
  $(".stimulus").on("click", ".transfer-link-open-password", function (e) {
    const prefix = "transfer-link-open-password-";
    const id = this.id.slice(this.id.indexOf(prefix) + prefix.length);
    $(this).hide();
    $("#transfer-link-password-" + id).get(0).type = 'text';
    $("#transfer-link-password-" + id).get(0).select();
    document.execCommand("copy");
    $("#copied-password-" + id).show();
    setTimeout(() => {
      $("#copied-password-" + id).fadeOut(1000);
    }, 1000);
    $("#transfer-link-password-" + id).removeClass("w-20");
    $("#transfer-link-password-" + id).addClass("w-28");
    $("#transfer-link-close-password-" + id).show();
  });

  // close password
  $(".stimulus").on("click", ".transfer-link-close-password", function (e) {
    const prefix = "transfer-link-close-password-";
    const id = this.id.slice(this.id.indexOf(prefix) + prefix.length);
    $(this).hide();
    $("#transfer-link-password-" + id).get(0).type = 'password';
    $("#transfer-link-password-" + id).removeClass("w-28");
    $("#transfer-link-password-" + id).addClass("w-20");
    $("#transfer-link-open-password-" + id).show();
  });

  function getAssetIDAndTransferLinkID(element_id) {
    const prefixAsset = "asset-";
    const prefixTransfer = "-transfer-link-";
    const asset_id = element_id.slice(element_id.indexOf(prefixAsset) + prefixAsset.length, element_id.indexOf(prefixTransfer));
    const transfer_link_id = element_id.slice(element_id.indexOf(prefixTransfer) + prefixTransfer.length);

    return [asset_id, transfer_link_id];
  }

  function setConfirmTextAndShowModal(element_id, status) {
    const [asset_id, transfer_link_id] = getAssetIDAndTransferLinkID(element_id);
    const confirmText = "YES, " + status.toUpperCase() + " NOW."
    const labelText = "Confirm " + status.slice(0, 1).toUpperCase() + status.slice(1);

    $("#confirm-asset-" + asset_id + "-transfer-link-" + transfer_link_id).text(confirmText);
    $(".label-confirm-transfer-link").text(labelText);
    $(".confirm-transfer-link-modal").show();
  }

  // close modal
  $(".stimulus").on("click", ".cancel-confirm-transfer-link", function (e) {
    $(".confirm-transfer-link-modal").hide();
  });

  // update status or delete link
  $(".stimulus").on("click", ".confirm-transfer-link-ajax", function (e) {
    const [asset_id, transfer_link_id] = getAssetIDAndTransferLinkID(this.id);
    let status = '';
    let method = 'PUT';

    if (this.textContent.indexOf("DISABLE") != -1) {
      status = '/disable';
    } else if (this.textContent.indexOf("ENABLE") != -1) {
      status = '/enable';
    } else {
      method = 'DELETE';
    }

    $.ajax({
      url: `/assets/${asset_id}/transfer_links/${transfer_link_id}${status}`,
      type: method,
      headers: {
        'x-csrf-token': csrfToken,
        'content-type': 'application/json',
        'accept': 'application/json'
      }
    });
  })

  // open modal confirm status disable
  $(".stimulus").on("click", ".disable-transfer-link", function (e) {
    setConfirmTextAndShowModal(this.id, 'disable');
  });

  // open modal confirm status enable
  $(".stimulus").on("click", ".enable-transfer-link", function (e) {
    setConfirmTextAndShowModal(this.id, 'enable');
  });

  // open modal confirm delete
  $(".stimulus").on("click", ".delete-transfer-link", function (e) {
    setConfirmTextAndShowModal(this.id, 'delete');
  });

  // stop close modal when click inside modal content
  $(".stimulus").on("click", ".content-confirm-transfer-link-modal", function (e) {
    e.stopPropagation();
    return;
  });

  // close modal
  $(".stimulus").on("click", ".bg-confirm-transfer-link-modal", function (e) {
    $(".confirm-transfer-link-modal").hide();
  });
});
